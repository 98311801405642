@import "~antd/dist/antd.css";

@font-face {
  font-family: "InterUI";
  font-style: normal;
  font-weight: 400;
  src: url("src/assets/fonts/Inter-UI-Regular.woff2") format("woff2"),
    url("src/assets/fonts/Inter-UI-Regular.woff") format("woff");
}

@font-face {
  font-family: "InterUI";
  font-style: italic;
  font-weight: 400;
  src: url("src/assets/fonts/Inter-UI-Italic.woff2") format("woff2"),
    url("src/assets/fonts/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: "InterUI";
  font-style: normal;
  font-weight: 500;
  src: url("src/assets/fonts/Inter-UI-Medium.woff2") format("woff2"),
    url("src/assets/fonts/Inter-UI-Medium.woff") format("woff");
}

@font-face {
  font-family: "InterUI";
  font-style: italic;
  font-weight: 500;
  src: url("src/assets/fonts/Inter-UI-MediumItalic.woff2") format("woff2"),
    url("src/assets/fonts/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "InterUI";
  font-style: normal;
  font-weight: 700;
  src: url("src/assets/fonts/Inter-UI-Bold.woff2") format("woff2"),
    url("src/assets/fonts/Inter-UI-Bold.woff") format("woff");
}

@font-face {
  font-family: "InterUI";
  font-style: italic;
  font-weight: 700;
  src: url("src/assets/fonts/Inter-UI-BoldItalic.woff2") format("woff2"),
    url("src/assets/fonts/Inter-UI-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "InterUI";
  font-style: normal;
  font-weight: 900;
  src: url("src/assets/fonts/Inter-UI-Black.woff2") format("woff2"),
    url("src/assets/fonts/Inter-UI-Black.woff") format("woff");
}

@font-face {
  font-family: "InterUI";
  font-style: italic;
  font-weight: 900;
  src: url("src/assets/fonts/Inter-UI-BlackItalic.woff2") format("woff2"),
    url("src/assets/fonts/Inter-UI-BlackItalic.woff") format("woff");
}

@font-face {
  font-family: "HK Nova";
  font-style: normal;
  font-weight: 500;
  src: url("src/assets/fonts/HKNova-Medium.woff2") format("woff2"),
    url("src/assets/fonts/HKNova-Medium.woff") format("woff");
}

@font-face {
  font-family: "Verlag";
  font-style: normal;
  font-weight: 500;
  src: url("src/assets/fonts/Verlag/VerlagXLight.woff") format("woff");
}

@font-face {
  font-family: "Verlag Bold";
  font-style: normal;
  src: url("src/assets/fonts/Verlag/VerlagBold.woff") format("woff");
}

@font-face {
  font-family: "Silk Serif";
  font-style: normal;
  font-weight: 500;
  src: url("src/assets/fonts/SilkSerif/Silk Serif Regular.otf")
    format("opentype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body,
html,
#root {
  height: 100%;
}

.menu-title,
.multi-menu-title,
.category-title,
.parent-category-title,
.child-category-title {
  font-family: "InterUI", sans-serif;
}

.sidebar-parent-category-title {
  color: black;
  font-weight: 700;
}

.menu-description,
.item-title,
.item-price {
  font-family: "HK Nova", sans-serif;
}

.category-items-1 {
  background: rgba(247, 255, 250, 0.75);
}

.category-items-2 {
  background: rgb(245 246 247 / 75%);
}

.menu-section-child-1 {
  background: rgba(245, 246, 247, 0.5);
}

.menu-section-child-2 {
  background: rgba(247, 255, 250, 0.5);
}

.ant-drawer-header {
  border-bottom: 0px solid #f0f0f0;
}

.menuBackground-0,
.menuBackground-0 .ant-layout-header,
.menuBackground-0 .ant-layout-footer {
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
}

.menuBackground-0 .ant-layout-header,
.menuBackground-0 .ant-layout-footer {
  background-color: rgb(0 0 0 / 15%);
}

.menuBackground-1,
.menuBackground-1 .ant-layout-header,
.menuBackground-1 .ant-layout-footer,
.menuBackground-1-sidebar .ant-drawer-body,
.menuBackground-1-sidebar .ant-drawer-header {
  background: #f5f0d7 !important;
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-1.png") !important;
}

.menuBackground-2,
.menuBackground-2 .ant-layout-header,
.menuBackground-2 .ant-layout-footer,
.menuBackground-2-sidebar .ant-drawer-body,
.menuBackground-2-sidebar .ant-drawer-header {
  background: #f5f0d7 !important;
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-2.jpg") !important;
}

.menuBackground-3,
.menuBackground-3 .ant-layout-header,
.menuBackground-3 .ant-layout-footer,
.menuBackground-3-sidebar .ant-drawer-body,
.menuBackground-3-sidebar .ant-drawer-header {
  background: #f5f0d7 !important;
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-3.jpg") !important;
}

.menuBackground-4,
.menuBackground-4 .ant-layout-header,
.menuBackground-4 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-4.jpg") !important;
  background-size: cover !important;
}

.menuBackground-4-sidebar .ant-drawer-body,
.menuBackground-4-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-4.jpg") !important;
  background-size: cover !important;
}

.menuBackground-5,
.menuBackground-5 .ant-layout-header,
.menuBackground-5 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-5.jpg") !important;
  background-size: cover !important;
}

.menuBackground-5-sidebar .ant-drawer-body,
.menuBackground-5-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-5.jpg") !important;
}

.menuBackground-6,
.menuBackground-6 .ant-layout-header,
.menuBackground-6 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-6.jpg") !important;
  background-size: cover !important;
}

.menuBackground-6-sidebar .ant-drawer-body,
.menuBackground-6-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-6.jpg") !important;
}

.menuBackground-7,
.menuBackground-7 .ant-layout-header,
.menuBackground-7 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-7.jpg") !important;
  background-size: cover;
}

.menuBackground-7-sidebar .ant-drawer-body,
.menuBackground-7-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-7.jpg") !important;
}

.menuBackground-8,
.menuBackground-8 .ant-layout-header,
.menuBackground-8 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-8.jpg") !important;
  background-size: cover !important;
}

.menuBackground-8-sidebar .ant-drawer-body,
.menuBackground-8-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-8.jpg") !important;
}

.menuBackground-9,
.menuBackground-9 .ant-layout-header,
.menuBackground-9 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-9.jpg") !important;
  background-size: cover !important;
}

.menuBackground-9-sidebar .ant-drawer-body,
.menuBackground-9-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-9.jpg") !important;
}

.menuBackground-10 .ant-layout-header,
.menuBackground-10 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-10.jpg") !important;
  background-size: cover !important;
}

.menuBackground-10-sidebar .ant-drawer-body,
.menuBackground-10-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-10.jpg") !important;
}

.menuBackground-10 {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-10.jpg") !important;
  background-size: contain !important;
}

/*b11*/

.menuBackground-11 .ant-layout-header,
.menuBackground-11 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-11.png") !important;
  background-size: cover !important;
}

.menuBackground-11-sidebar .ant-drawer-body,
.menuBackground-11-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-11.png") !important;
}

.menuBackground-11 {
  background-image: url("src/assets/backgrounds/style-11.png") !important;
  background-size: contain !important;
}

.menuBackground-11 .category-items-1 {
  background: rgba(247, 255, 250, 0.4);
}

.menuBackground-11 .category-items-2 {
  background: rgb(245 246 247 / 30%);
}

.menuBackground-11 .menu-section-child-1 {
  background: rgba(245, 246, 247, 0.2);
}

.menuBackground-11 .menu-section-child-2 {
  background: rgba(247, 255, 250, 0.2);
}

.menuBackground-11 .parent-category-title {
  font-weight: 700;
}

.menuBackground-11 .category-title,
.menuBackground-11 .child-category-title {
  font-weight: 600;
}

.menuBackground-11 .child-category-title {
  background: black;
  color: #d1baa5 !important;
}

.menuBackground-11 .category-title,
.menuBackground-11 .parent-category-title,
.menuBackground-11 .child-category-title,
.menuBackground-11 .item-title,
.menuBackground-11 .item-price {
  color: black;
}

.menuBackground-11 .under-category-line {
  visibility: hidden;
}

/*b11-end*/

/* b12 */

.menuBackground-12 .ant-layout-footer,
.menuBackground-12 .ant-layout-header {
  background-color: rgb(245 240 215 / 0%) !important;
}

.menuBackground-12-sidebar .ant-drawer-body,
.menuBackground-12-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-12.jpg") !important;
}

.menuBackground-12 .multi-menu-title {
  background: rgba(247, 255, 250, 0.7) !important;
}

.menuBackground-12 {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-12.jpg"),
    url("src/assets/backgrounds/style-12-1.jpg"),
    url("src/assets/backgrounds/style-12-2.jpg"),
    url("src/assets/backgrounds/style-12-3.jpg");
  background-repeat: no-repeat, no-repeat, no-repeat, repeat;
  background-position: right bottom, left top;
  background-size: cover !important;
}
/* b12-end*/

/* b13 */

.menuBackground-13 .ant-layout-header,
.menuBackground-13 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-13.jpg") !important;
  background-size: cover !important;
}

.menuBackground-13 .ant-layout-footer a,
.menuBackground-13 .ant-layout-footer {
  color: #d9d9d9 !important;
}

.menuBackground-13-sidebar .ant-drawer-body,
.menuBackground-13-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-13.jpg") !important;
}

.menuBackground-13 {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-13.jpg") !important;
  background-size: cover !important;
}

/* b13-end */

/* b14 */

.menuBackground-14 .ant-layout-header {
  background-color: white !important;
  /*background-image: url("src/assets/backgrounds/style-13.jpg") !important;
  background-size: cover !important;*/
}

.menuBackground-14 .ant-layout-footer {
  background-color: white !important;
  /*background-image: url("src/assets/backgrounds/style-13.jpg") !important;
  background-size: cover !important;*/
}

.menuBackground-14-sidebar .ant-drawer-body,
.menuBackground-14-sidebar .ant-drawer-header {
  background-color: #f0f0ec !important;
  /*background-image: url("src/assets/backgrounds/style-13.jpg") !important;*/
}

.menuBackground-14 {
  background-color: #f0f0ec !important;
  /*background-image: url("src/assets/backgrounds/style-13.jpg") !important;
  background-size: cover !important;*/
}

.menuBackground-14 .category-title,
.menuBackground-14 .parent-category-title {
  color: #0d4c46;
}

.menuBackground-14 .parent-category-title {
  font-family: "Silk Serif", sans-serif;
}

.menuBackground-14 .category-title,
.menuBackground-14 .child-category-title {
  font-family: "Verlag", sans-serif;
}

.menuBackground-14 .under-category-line {
  background: #9f662f !important;
  max-width: 80px !important;
}

.menuBackground-14-sidebar .sidebar-parent-category-title {
  font-family: "Silk Serif", sans-serif;
}

.menuBackground-14 .item-title,
.menuBackground-14 .item-price {
  font-family: "Verlag Bold", sans-serif;
}

.menuBackground-14 .item-description {
  font-family: "Verlag", sans-serif;
}

/* b14-end */

/*b15*/

.menuBackground-15 .ant-layout-header,
.menuBackground-15 .ant-layout-footer {
  background-color: white !important;
  /*background-image: url("src/assets/backgrounds/style-11.png") !important;
  background-size: cover !important;*/
}

.menuBackground-15-sidebar .ant-drawer-body,
.menuBackground-15-sidebar .ant-drawer-header {
  background-color: white !important;
  /*background-image: url("src/assets/backgrounds/style-11.png") !important;*/
}

.menuBackground-15 {
  background-color: white !important;
  /*background-image: url("src/assets/backgrounds/style-11.png") !important;
  background-size: contain !important;*/
}

.menuBackground-15 .parent-category-title {
  font-weight: 700;
}

.menuBackground-15 .category-title,
.menuBackground-15 .child-category-title {
  /*font-family: "Verlag", sans-serif;*/
  font-size: 18px;
}

.menuBackground-15 .under-category-line {
  background: black !important;
  max-width: 200px !important;
  margin-top: 0;
}

.menuBackground-15 .multi-menu-title {
  border-radius: 0px !important;
  border: 1px solid black !important;
  /*border: none !important;*/
  /*font-family: "Verlag", sans-serif;*/
  color: black;
  padding-top: 15px;
}

.menuBackground-15 .ant-card-body {
  padding-top: 0;
  width: 200px;
}

.menuBackground-15 .category-title,
.menuBackground-15 .child-category-title {
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 0 !important;
  text-align: left;
  line-height: 35px;
}

.menuBackground-15 .category-title:before {
  content: url("https://menulink.lt/images/icons/apple-icon.png");
  vertical-align: middle;
  margin-right: 5px;
}

.menuBackground-15 .multi-menu-title:before {
  content: url("https://menulink.lt/images/icons/apple-icon.png");
  vertical-align: middle;
  margin-right: 5px;
}

.menuBackground-15 .item-title,
.menuBackground-15 .item-price {
  font-weight: 500;
}

.menuBackground-15 .menu-section-child-1,
.menuBackground-15 .menu-section-child-2 {
  padding-bottom: 0 !important;
}

/*b15-end*/

/* b16 */

.menuBackground-16 .ant-layout-header,
.menuBackground-16 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-16-2.png") !important;
  background-size: cover !important;
}

.menuBackground-16 .ant-layout-footer a,
.menuBackground-16 .ant-layout-footer {
  color: #000000 !important;
}

.menuBackground-16-sidebar .ant-drawer-body,
.menuBackground-16-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-16-2.png") !important;
}

.menuBackground-16 {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-16-2.png") !important;
  background-size: cover !important;
}

.menuBackground-16 .item-title,
.menuBackground-16 .item-price,
.menuBackground-16 .category-title,
.menuBackground-16 .child-category-title {
  color: #bb4a16;
}

.menuBackground-16 .item-description,
.menuBackground-16 .category-description {
  color: #d85215;
}

/* b16-end */

/* b17 */

.menuBackground-17 .ant-layout-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-17.jpg") !important;
  background-size: cover !important;
}

.menuBackground-17 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-17.jpg") !important;
  background-size: cover !important;
}

.menuBackground-17 .ant-layout-header .ant-layout-content {
  visibility: hidden;
}

.menuBackground-17 .ant-layout-footer a,
.menuBackground-17 .ant-layout-footer {
  color: #000000 !important;
}

.menuBackground-17-sidebar .ant-drawer-body,
.menuBackground-17-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-17.jpg") !important;
  background-size: contain !important;
}

.menuBackground-17 {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-17.jpg") !important;
  background-size: cover !important;
  background: top;
}

/* b17 end */

/* b18 */

.menuBackground-18 .ant-layout-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-18.jpg") !important;
  background-size: cover !important;
}

.menuBackground-18 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-18.jpg") !important;
  background-size: cover !important;
}

.menuBackground-18 .ant-layout-header .ant-layout-content {
  visibility: hidden;
}

.menuBackground-18 .ant-layout-footer a,
.menuBackground-18 .ant-layout-footer {
  color: #000000 !important;
}

.menuBackground-18-sidebar .ant-drawer-body,
.menuBackground-18-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-18.jpg") !important;
  background-size: contain !important;
}

.menuBackground-18 {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-18.jpg") !important;
  background-size: cover !important;
  background-position: center;
}

/* b17 end */

/* b18 white */

.menuBackground-19,
.menuBackground-19 .ant-layout-header,
.menuBackground-19 .ant-layout-footer {
  background-color: #ffffff !important;
}

/* b18 white end */

/* private styles */

.menuBackground-n1 .ant-layout-header {
  background-color: #fcfaf4 !important;
  /*background-image: url("src/assets/backgrounds/style-17.jpg") !important;*/
  background-image: url("https://menu.menulink.lt/assets/nerija/logo-3.png") !important;
  background-size: contain !important;
  /*height: 150px;*/
  min-height: 100px !important;
  background-position: center;
  background-repeat: no-repeat;
}

.menuBackground-n1 .ant-layout-header .ant-image {
  visibility: hidden;
}

.menuBackground-n1 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-18.jpg") !important;
  background-size: cover !important;
}

.menuBackground-n1 .ant-layout-header .ant-layout-content {
  visibility: hidden;
}

.menuBackground-n1 .ant-layout-footer a,
.menuBackground-n1 .ant-layout-footer {
  color: #000000 !important;
}

.menuBackground-n1-sidebar .ant-drawer-body,
.menuBackground-n1-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-17.jpg") !important;
  background-size: contain !important;
}

.menuBackground-n1 {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-18.jpg") !important;
  background-size: cover !important;
  background: top;
}

/******************************/

.menuBackground-n2 .ant-layout-header {
  background-color: #fcfaf4 !important;
  /*background-image: url("src/assets/backgrounds/style-17.jpg") !important;*/
  background-image: url("https://menu.menulink.lt/assets/nerija/logo-3.png") !important;
  background-size: contain !important;
  /*height: 150px;*/
  min-height: 100px !important;
  background-position: center;
  background-repeat: no-repeat;
}

.menuBackground-n2 .ant-layout-header .ant-image {
  visibility: hidden;
}

.menuBackground-n2 .ant-layout-footer {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-18.jpg") !important;
  background-size: cover !important;
}

.menuBackground-n2 .ant-layout-header .ant-layout-content {
  visibility: hidden;
}

.menuBackground-n2 .ant-layout-footer a,
.menuBackground-n2 .ant-layout-footer {
  color: #000000 !important;
}

.menuBackground-n2-sidebar .ant-drawer-body,
.menuBackground-n2-sidebar .ant-drawer-header {
  background-color: #f5f0d7 !important;
  background-image: url("src/assets/backgrounds/style-17.jpg") !important;
  background-size: contain !important;
}

.menuBackground-n2 {
  background-color: #f5f0d7 !important;
  background-image: url("https://menu.menulink.lt/assets/nerija/bg-1.jpg") !important;
  background-size: cover !important;
  background-position-y: 100px;
  background-position-x: -553px;
}

/****************************************/

.menuBackground-ps1,
.menuBackground-ps1 .ant-layout-header,
.menuBackground-ps1 .ant-layout-footer {
  background-color: #ffffff !important;
  color: #000000;
  padding-top: 20px;
}

.menuBackground-ps1 {
  background-color: #f5f0d7 !important;
  background-image: url("https://menu.menulink.lt/assets/ps/ps-background.jpg") !important;
  background-size: cover !important;
  background-position: bottom;
}

.menuBackground-ps1 .multi-menu-title {
  border-radius: 0 !important;
}

/****************************************/

.menuBackground-ps2 .menu-sidebar-button {
  color: #ffffff !important;
}

.menuBackground-ps2 .ant-layout-header img.header-image {
  background-color: #ffffff !important;
}

.menuBackground-ps2,
.menuBackground-ps2 .ant-layout-header,
.menuBackground-ps2 .ant-layout-footer {
  background-color: #ffffff !important;
  background-size: cover !important;
  color: #000000;
  padding-top: 20px;
  background-position: center;
}

.menuBackground-ps2 .ant-layout-header {
  background-image: none !important;
  opacity: 0.45;
}

.menuBackground-ps2 .ant-layout-footer {
  background-image: none !important;
  opacity: 0.3;
}

.menuBackground-ps2 .item-title {
  font-weight: 400 !important;
}

.menuBackground-ps2 {
  background-color: #000 !important;
  background-image: url(https://menu.menulink.lt/assets/ps/ps2-background.jpg) !important;
  background-position: 0px 130px;
  background-size: contain !important;
  background-repeat: no-repeat;
}

.menuBackground-ps2 .multi-menu-title {
  border-radius: 0 !important;
}

/* private styles end */
